import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { countryStrings } from 'components/blocks/deprecated/ClinicLocator/ClinicLocator'
import { GeolocationStatus } from 'components/blocks/deprecated/ClinicLocator/types'
import { useFetchClinicLocations } from 'components/blocks/deprecated/ClinicLocator/useFetchClinics'
import { useMapboxLocation } from 'components/blocks/deprecated/ClinicLocator/useMapboxLocation'
import { ClinicSearchPanel } from 'components/blocks/layout/ClinicSearchPanel'
import { Icon } from 'components/ui'
import VideoOverlay from 'components/ui/VideoOverlay'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { SelectClinic } from 'components/ui/deprecated/SelectClinic'
import { Text } from 'components/ui/deprecated/Text'
import useDebounce from 'lib/hooks/useDebounce'
import { useScroll } from 'lib/hooks/useScroll'
import { useTranslation } from 'lib/i18n'
import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { ClinicSearchHeroStoryblok } from 'lib/storyblok/types'
import {
  getClinicDistance,
  getDistance,
  getStoryblokImageAttributes,
  textByLine,
} from 'lib/utils/content'
import { isSourceType } from 'lib/utils/isSourceType'
import { cn } from 'lib/utils/tailwind'

type Props = {
  block: ClinicSearchHeroStoryblok
}

const ClinicSearchHero: React.FC<Props> = ({ block }) => {
  const { headline, tagline, search_placeholder, variant, images, media } =
    block
  const [isSearchVisible, setSearchVisible] = useState<boolean>(false)
  const [videoOverlay, setVideoOverlay] = useState<string | null>(null)

  const { scrollPosition } = useScroll()
  const containerRef = useRef<HTMLDivElement>(null)

  const {
    query: { lang },
  } = useRouter()
  const { i18n } = useTranslation()
  const [, region] = ((lang as string) || '').split('-')
  const [search, setSearch] = useState('')
  const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>(
    GeolocationStatus.Loading
  )

  const [userLocation, setUserLocation] = useState<{
    lat: number
    lng: number
  } | null>(null)

  useEffect(() => {
    try {
      if (
        'geolocation' in navigator &&
        (window.location.protocol === 'https:' ||
          process.env.NODE_ENV === 'development')
      ) {
        setGeolocationStatus(GeolocationStatus.Ready)
      }
    } catch (error) {
      setGeolocationStatus(GeolocationStatus.Unsupported)
    }
  }, [])

  const disableNearMe = () => {
    setUserLocation(null)
    setGeolocationStatus(GeolocationStatus.Ready)
  }

  const handleNearMe = useCallback(() => {
    if (userLocation) {
      disableNearMe()
      return
    }

    setGeolocationStatus(GeolocationStatus.Loading)

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocationStatus(GeolocationStatus.Success)

        setSearch('')

        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      (error) => {
        console.error(error)
        setGeolocationStatus(GeolocationStatus.Error)
      },
      {
        enableHighAccuracy: true,
      }
    )
  }, [userLocation])

  const {
    data,
    isError: clinicLoadingErrored,
    isLoading: isLoadingClinics,
  } = useFetchClinicLocations()

  const debouncedSearch = useDebounce(search)

  const hasSearch = debouncedSearch || userLocation

  const { status: locationStatus, searchedLocation: mapboxSearchedLocation } =
    useMapboxLocation({
      search,
      userLocation,
    })

  const locationStatusIsLoading = locationStatus === 'loading'
  const locationStatusIsError = locationStatus === 'error'

  const clinics = useMemo(() => {
    let filteredClinics = data

    if (!hasSearch && !!region) {
      filteredClinics = filteredClinics
        .filter((c) => c.content.country === region)
        .sort((a) => (a.content.country === region ? -1 : 1))
    }

    if (userLocation) {
      const clinicsWithDistance = filteredClinics.map((clinic) => {
        const [lat, lng] = clinic.content.coordinates.split(',')
        const distance = getDistance(
          Number(lat),
          Number(lng),
          userLocation.lat,
          userLocation.lng
        )
        return { ...clinic, distance }
      })

      filteredClinics = clinicsWithDistance.sort(
        (a, b) => a.distance - b.distance
      )
    }

    return filteredClinics
  }, [data, hasSearch, region, userLocation])

  const clinicsArray = useMemo(() => {
    const allClinics = clinics?.map((clinic) => {
      const [lat, lng] = clinic.content.coordinates.split(',')
      const distance = getClinicDistance({
        userLocation,
        mapboxSearchedLocation,
        clinicCoordinates: [lat, lng],
      })

      return {
        id: clinic.id,
        name: clinic.content.name,
        image: clinic.content.image.filename,
        country: countryStrings[clinic.content.country]
          ? i18n(countryStrings[clinic.content.country])
          : null,
        url: clinic.content.website_url || '/',
        distance,
      }
    })

    if (userLocation || mapboxSearchedLocation) {
      return allClinics.sort((a, b) => (a.distance || 0) - (b.distance || 0))
    }

    return allClinics
  }, [clinics, mapboxSearchedLocation, userLocation])

  const maxTranslate = 200

  const translateX = containerRef.current
    ? Math.min(
        Math.floor(
          (Math.max(0, scrollPosition) / containerRef.current.offsetHeight) *
            maxTranslate
        ),
        maxTranslate
      )
    : 0

  return (
    <div {...storyblokEditable(block)} className="w-full overflow-hidden">
      {videoOverlay && (
        <VideoOverlay
          videoUrl={videoOverlay}
          setVideoOverlay={setVideoOverlay}
        />
      )}

      <div
        className="container relative mx-auto max-w-screen-desktop"
        ref={containerRef}
      >
        <div className="flex w-full flex-col items-center justify-center px-5 py-10 md:px-0 lg:py-15">
          {/* Header */}
          <div className="flex flex-col items-center gap-8 text-center md:max-w-[905px]">
            <div className="flex flex-col items-center gap-5 text-center">
              {headline && (
                <h1 className="text-title-card font-regular md:text-display">
                  {textByLine(headline, (part, i, length) => {
                    return (
                      <>
                        {part}
                        {i + 1 < length && <br />}
                      </>
                    )
                  })}
                </h1>
              )}

              {tagline && (
                <p className="max-w-[670px] text-twenty font-light md:text-title-small">
                  {textByLine(tagline, (part, i, length) => {
                    return (
                      <>
                        {part}
                        {i + 1 < length && <br />}
                      </>
                    )
                  })}
                </p>
              )}
            </div>

            {/* Clinic search desktop */}
            <div className="hidden w-auto flex-col justify-center md:relative md:flex">
              <SelectClinic
                name="search"
                onChange={(e) => {
                  setSearch(e.currentTarget.value)
                }}
                isLoading={
                  (isLoadingClinics || locationStatusIsLoading) &&
                  !clinicLoadingErrored
                }
                value={search}
                options={clinicsArray}
                onChangeNearMe={handleNearMe}
                checkedNearMe={!!userLocation}
                placeholder={search_placeholder}
                disabledNearMe={locationStatusIsLoading}
                geolocationStatusNearMe={geolocationStatus}
                hasError={clinicLoadingErrored || locationStatusIsError}
              />
            </div>

            {/* Clinic search mobile */}
            <div className="block w-full md:hidden">
              <OldButton
                variant="ghost"
                onClick={() => setSearchVisible(true)}
                className="w-full justify-start"
              >
                <Icon icon="search" />
                <Text as="span" color="foreground.subtle" className="ml-2">
                  {search_placeholder}
                </Text>
              </OldButton>
            </div>

            <ClinicSearchPanel
              isVisible={isSearchVisible}
              onClose={() => {
                setSearchVisible(false)
                setSearch('')
              }}
              placeholder={search_placeholder}
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value)
              }}
              disabledNearMe={locationStatusIsLoading}
              checkedNearMe={!!userLocation}
              onChangeNearMe={handleNearMe}
              geolocationStatusNearMe={geolocationStatus}
              isLoading={
                (isLoadingClinics || locationStatusIsLoading) &&
                !clinicLoadingErrored
              }
              hasError={clinicLoadingErrored || locationStatusIsError}
              options={clinicsArray}
            />
          </div>
        </div>

        {variant !== 'image-composition' && images && images?.length > 2 && (
          <div
            style={{
              transform: `translateX(-${translateX}px)`,
            }}
            className="z-10 -ml-12.5 flex gap-5 pb-10 pl-7 md:-ml-0 md:pb-13"
          >
            {(!media || media?.length === 0) &&
              images &&
              images.map((image, i) => (
                <div className="flex flex-row-reverse gap-5 md:flex-row">
                  <div
                    className={cn(
                      'rounded-tr-12 rounded-bl-12 relative block h-[205px] flex-shrink-0 overflow-hidden md:h-[280px]',
                      i === 1 ? 'w-[277px]' : 'w-[289px] md:w-[600px]'
                    )}
                  >
                    <NextImage
                      fill
                      sizes="300"
                      priority
                      src={getStoryblokImageAttributes(image).src}
                      alt={getStoryblokImageAttributes(image).alt}
                      className="object-cover"
                    />
                  </div>

                  {i === 0 && (
                    <div className="size-[205px] rounded-8 bg-[linear-gradient(131deg,#F5E4B5_-20.07%,#F8E0DF_132.31%)] md:size-[280px]" />
                  )}
                </div>
              ))}
            {media &&
              media.length > 0 &&
              media.map((asset, i) => (
                <div className="flex flex-row-reverse gap-5 md:flex-row">
                  {i === 0 && (
                    <div className="size-[205px] rounded-8 bg-[linear-gradient(131deg,#F5E4B5_-20.07%,#F8E0DF_132.31%)] md:size-[280px]" />
                  )}

                  <div
                    className={cn(
                      'rounded-tr-12 rounded-bl-12 relative block h-[205px] flex-shrink-0 overflow-hidden md:h-[280px]',
                      i === 1 ? 'w-[277px]' : 'w-[289px] md:w-[600px]'
                    )}
                  >
                    {isSourceType(asset.media.filename, 'image') ? (
                      <NextImage
                        fill
                        sizes="300"
                        priority
                        src={getStoryblokImageAttributes(asset.media).src}
                        alt={getStoryblokImageAttributes(asset.media).alt}
                        className="object-cover"
                      />
                    ) : (
                      <div className="group relative h-full w-full">
                        <video
                          muted
                          playsInline
                          loop
                          autoPlay
                          disablePictureInPicture
                          className="h-full w-full object-cover"
                        >
                          <source src={asset.media.filename} />
                        </video>
                        <button
                          onClick={() =>
                            asset.video_youtube_link
                              ? setVideoOverlay(asset.video_youtube_link)
                              : setVideoOverlay(asset.media.filename)
                          }
                          className="absolute right-2 bottom-2 rounded-full bg-white/80 opacity-100 md:opacity-0 md:transition-opacity md:hover:bg-white md:group-hover:opacity-100 flex items-center justify-center p-2"
                          aria-label="Open video"
                        >
                          <Icon icon="expand" size={16} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}

        {/* Composition */}
        {variant === 'image-composition' && images?.length === 7 && (
          <div className="sm:items-centera flex w-full items-center pb-10 sm:justify-center sm:pb-15">
            <div className="relative flex -translate-x-5 gap-5 sm:h-full sm:-translate-x-0 sm:items-end sm:px-20">
              {/* Left curves */}
              <NextImage
                alt=""
                width={490}
                height={590}
                src="/assets/heros/curves.png"
                role="presentation"
                className="pointer-events-none absolute -bottom-[220px] -left-[130px] -z-10 hidden flex-shrink-0 scale-50 sm:block"
              />

              {/* Right curves */}
              <NextImage
                alt=""
                width={490}
                height={590}
                src="/assets/heros/curves.png"
                role="presentation"
                className="pointer-events-none absolute -right-[240px] -top-25 -z-10 hidden flex-shrink-0 scale-50 sm:block"
              />
              {/* Columns */}
              <div className="flex flex-col gap-5 sm:mt-13">
                <div className="h-[98px] w-[196px] rounded-5 bg-[linear-gradient(128deg,hsl(var(--twc-background-secondary-lgbtqia)/1)_-51.44%,hsl(var(--twc-background-secondary-donation)/1)_100%)]" />

                {images[0] && (
                  <div className="rounded-tl-br relative block h-[227px] w-[196px] overflow-hidden">
                    <NextImage
                      alt=""
                      fill
                      sizes="200"
                      src={getStoryblokImageAttributes(images[0]).src}
                      role="presentation"
                      className="pointer-events-none flex-shrink-0 object-cover"
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-5">
                {images[1] && (
                  <div className="rounded-tl-br relative block h-[193px] w-[305px] overflow-hidden sm:h-[246px]">
                    <NextImage
                      alt=""
                      fill
                      sizes="300"
                      src={getStoryblokImageAttributes(images[1]).src}
                      role="presentation"
                      className="pointer-events-none flex-shrink-0 object-cover"
                    />
                  </div>
                )}

                <div className="flex gap-5">
                  <div className="h-[132px] w-[58px] rounded-5 bg-[linear-gradient(12deg,hsl(var(--twc-background-secondary-advice)/1)_37.62%,#DAE0F7_138.47%)]" />

                  {images[2] && (
                    <div className="rounded-tl-br relative block h-[132px] w-[227px] overflow-hidden">
                      <NextImage
                        alt=""
                        fill
                        sizes="300"
                        src={getStoryblokImageAttributes(images[2]).src}
                        role="presentation"
                        className="pointer-events-none flex-shrink-0 object-cover"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-[89px] hidden flex-col gap-5 sm:flex">
                {images[3] && (
                  <div className="rounded-tl-br relative block h-[191px] w-[197px] overflow-hidden">
                    <NextImage
                      alt=""
                      fill
                      sizes="200"
                      src={getStoryblokImageAttributes(images[3]).src}
                      role="presentation"
                      className="pointer-events-none flex-shrink-0 object-cover"
                    />
                  </div>
                )}

                <div className="h-[97px] w-[197px] rounded-5 bg-[linear-gradient(131deg,hsl(var(--twc-background-secondary-donation)/1)_6.62%,hsl(var(--twc-background-secondary-condition)/1)_100%)]" />
              </div>

              <div className="mt-13 hidden flex-col gap-5 sm:flex">
                <div className="flex gap-5">
                  <div className="h-[104px] w-[162px] rounded-5 bg-[linear-gradient(129deg,hsl(var(--twc-background-secondary-advice)/1)_-0.65%,#DAE0F7_132.42%)]" />

                  {images[4] && (
                    <div className="rounded-tl-br relative block h-[104px] w-[123px] overflow-hidden">
                      <NextImage
                        alt=""
                        fill
                        sizes="300"
                        src={getStoryblokImageAttributes(images[4]).src}
                        role="presentation"
                        className="pointer-events-none flex-shrink-0 object-cover"
                      />
                    </div>
                  )}
                </div>

                <div className="rounded-tl-br relative block h-[221px] w-[305px] overflow-hidden">
                  {images[5] && (
                    <NextImage
                      alt=""
                      fill
                      sizes="300"
                      src={getStoryblokImageAttributes(images[5]).src}
                      role="presentation"
                      className="pointer-events-none flex-shrink-0 object-cover"
                    />
                  )}
                </div>
              </div>

              <div className="hidden flex-col gap-5 sm:flex">
                <div className="rounded-tl-br relative block h-[257px] w-[197px] overflow-hidden">
                  {images[6] && (
                    <NextImage
                      alt=""
                      fill
                      sizes="200"
                      src={getStoryblokImageAttributes(images[6]).src}
                      role="presentation"
                      className="pointer-events-none flex-shrink-0 object-cover"
                    />
                  )}
                </div>

                <div className="h-[110px] w-[197px] rounded-5 bg-[linear-gradient(129deg,hsl(var(--twc-background-secondary-condition)/1)_-0.65%,#F4DCE5_132.42%)]" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { ClinicSearchHero }
