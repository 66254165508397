import { AnimatePresence, motion } from 'framer-motion'
import NextImage from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import styled from 'styled-components'

import { ContentLoader } from 'components/blocks/deprecated/ContentLoader'
import { Icon } from 'components/ui'
import { Checkbox } from 'components/ui/deprecated/Checkbox'
import { ErrorText } from 'components/ui/deprecated/Field'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { SearchInput } from 'components/ui/deprecated/SearchInput'
import { Text } from 'components/ui/deprecated/Text'
import { Theme } from 'components/ui/deprecated/Theme'
import { useBodyLock } from 'lib/hooks/useBodyLock'
import { useTranslation } from 'lib/i18n'
import { addAlphaToColor, focusOutline } from 'lib/style'
import { getStoryblokImageAttributes } from 'lib/utils/content'
import { convertDistance } from 'lib/utils/convertDistance'
import { removeLeadingAndTrailingSlash } from 'lib/utils/string'

import { GeolocationStatus } from '../deprecated/ClinicLocator/types'

type Props = {
  isVisible: boolean
  onClose: () => void
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  disabledNearMe: boolean
  checkedNearMe: boolean
  onChangeNearMe: () => void
  geolocationStatusNearMe: GeolocationStatus
  isLoading: boolean
  hasError: boolean
  options: {
    id: string
    name: string
    image: string
    country: string | null
    distance: number | null
    url: string
  }[]
}

export const ClinicSearchPanel = ({
  isVisible,
  onClose,
  placeholder,
  value,
  onChange,
  geolocationStatusNearMe,
  disabledNearMe,
  checkedNearMe,
  onChangeNearMe,
  isLoading,
  hasError,
  options,
}: Props) => {
  const { i18n } = useTranslation()
  useBodyLock(isVisible)

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const {
    query: { lang },
  } = useRouter()
  const [, region] = ((lang as string) || '').split('-')

  const LOADER_AMOUNT = 12

  return (
    <AnimatePresence>
      {isVisible && (
        <Theme colorTheme="light">
          <Overlay
            onMouseDown={handleClose}
            onTouchStart={handleClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { delay: 0.1 } }}
            transition={{ ease: 'easeOut', duration: 0.1 }}
          >
            <Holder
              onMouseDown={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ type: 'tween', damping: 35, stiffness: 500 }}
            >
              <Wrapper>
                <SearchInput
                  placeholder={placeholder}
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  autoFocus
                />
                <OldButton
                  variant="ghost"
                  onClick={handleClose}
                  className="w-12 ml-3.5"
                >
                  <Icon icon="close" />
                </OldButton>
              </Wrapper>
              {geolocationStatusNearMe !== GeolocationStatus.Unsupported && (
                <NearMe
                  disabled={disabledNearMe}
                  checked={checkedNearMe}
                  label={i18n('clinicLocator.nearMe')}
                  onChange={() => onChangeNearMe()}
                />
              )}
              <InnerWrapper>
                {geolocationStatusNearMe === GeolocationStatus.Error ? (
                  <ErrorText as="div" variant="fourteen" className="block">
                    {i18n('clinicLocator.geolocationError')}
                  </ErrorText>
                ) : isLoading ? (
                  <Text as="p" variant="sixteen">
                    {i18n('select-clinic-header.select-clinic-searching')}
                  </Text>
                ) : hasError ? (
                  <div className="flex">
                    <Text as="p" variant="sixteen">
                      {i18n('select-clinic-header.select-clinic-error')}
                    </Text>
                    <StyledTextError as="p" variant="sixteen">
                      {value}
                    </StyledTextError>
                  </div>
                ) : (
                  <Text as="p" variant="sixteen">
                    {i18n('select-clinic-header.select-clinic-description')}
                  </Text>
                )}
                <ItemWrapper>
                  {isLoading &&
                    new Array(LOADER_AMOUNT).fill('').map((_, i) => (
                      <ContentLoader
                        key={i}
                        viewBox="0 0 406 60"
                        preserveAspectRatio="none"
                        style={{
                          opacity: Math.min(
                            ((LOADER_AMOUNT - 1 - i) *
                              (100 / LOADER_AMOUNT - 1) +
                              10) /
                              100,
                            1
                          ),
                        }}
                      >
                        <rect
                          x="2"
                          y="8"
                          rx="4"
                          ry="4"
                          width="44"
                          height="44"
                        />
                        <rect
                          x="58"
                          y="8"
                          rx="4"
                          ry="4"
                          width="260"
                          height="18"
                        />
                        <rect
                          x="58"
                          y="30"
                          rx="4"
                          ry="4"
                          width="225"
                          height="18"
                        />
                      </ContentLoader>
                    ))}
                  {!isLoading &&
                    options?.map((option, i) => {
                      const url = option.url.startsWith('http')
                        ? option.url
                        : `/${removeLeadingAndTrailingSlash(option.url)}`
                      return (
                        <Item href={url} key={`${option.name}-${i}`}>
                          <ImageHolder>
                            {option.image && (
                              <NextImage
                                src={
                                  getStoryblokImageAttributes(
                                    { filename: option.image },
                                    '400x400/smart/filters:format(webp)'
                                  ).src
                                }
                                style={{ objectFit: 'cover', height: '100%' }}
                                alt={option.name}
                                fill
                              />
                            )}
                          </ImageHolder>
                          <TextWrapper>
                            <Text
                              as="p"
                              variant="sixteen/regular"
                              style={{ textAlign: 'left' }}
                            >
                              {option.name}
                            </Text>
                            <StyledDescription as="p" variant="sixteen">
                              {option.country}
                              {option.distance && (
                                <WrapperDistance>
                                  <Dot />
                                  {convertDistance(option.distance, region)}
                                </WrapperDistance>
                              )}
                            </StyledDescription>
                          </TextWrapper>
                        </Item>
                      )
                    })}
                </ItemWrapper>
              </InnerWrapper>
            </Holder>
          </Overlay>
        </Theme>
      )}
    </AnimatePresence>
  )
}

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
`

const Holder = styled(motion.div)`
  cursor: default;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
`

const Wrapper = styled.div`
  display: flex;
  padding: 0.5rem 1.25rem;
  align-items: center;
`

const NearMe = styled(Checkbox)`
  display: flex;
  margin: 0rem 1.25rem 1rem 1.25rem;
  width: fit-content;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  align-items: flex-start;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  text-align: left;
  border-top: 1px dashed
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};
`

const StyledTextError = styled(Text)`
  color: ${({ theme }) => theme.colors.accent.subtle};
  margin-left: 0.25rem;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  width: 100%;
`

const Item = styled(Link)`
  display: flex;
  align-items: start;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  border-radius: 0.75rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.default};
  ${({ theme }) => focusOutline(theme.colors.foreground.default)};
`
const ImageHolder = styled.div`
  display: block;
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  align-items: start;
`

const StyledDescription = styled(Text)`
  display: flex;
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 80)};
`

const WrapperDistance = styled.div`
  display: flex;
  align-items: center;
`

const Dot = styled.div`
  display: flex;
  width: 0.125rem;
  height: 0.125rem;
  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 80)};
  margin: 0px 0.25rem;
`
